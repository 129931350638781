export const productDetailView = (
  babelProdotto,
  productRegularPrice,
  productSpecialPrice,
  origin = `Product Detail page`,
  config
) => {
  if (!window.dataLayer) return;
  try {
    const { key_prod, productCode, pageLine } = babelProdotto;
    const regularPrice = productRegularPrice ? productRegularPrice : null;
    const specialPrice = productSpecialPrice
      ? productSpecialPrice
      : productRegularPrice
      ? productRegularPrice
      : null;
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item", //per trigger in SPA
      ecommerce: {
        value: specialPrice,
        currency: config?.currency || "EUR",
        items: [
          {
            item_name: productCode, // Name or ID is required.
            item_id: key_prod,
            price: regularPrice,
            item_brand: "Rosieres",
            item_category: pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
            item_list_name: origin ? origin : "Other",
            item_list_id: origin ? origin.toLowerCase().replaceAll(" ", "_") : "other",
            discount:
              specialPrice && regularPrice
                ? parseFloat((Number(regularPrice) - Number(specialPrice)).toFixed(2))
                : 0,
            index: 0,
            quantity: 1,
          },
        ],
      },
    });
  } catch (err) {
    console.error("Error in productDetailView: ", err);
  }
};

export const productImpression = (products, origin) => {
  if (!window.dataLayer) return;
  try {
    const list = products.map((item, index) => {
      const { key_prod, productCode, pageLine, productRegularPrice, productSpecialPrice } = item;
      const regularPrice = productRegularPrice ? productRegularPrice : null;
      const specialPrice = productSpecialPrice
        ? productSpecialPrice
        : productRegularPrice
        ? productRegularPrice
        : null;
      return {
        item_name: productCode,
        item_id: key_prod,
        item_brand: "Rosieres",
        price: regularPrice,
        item_list_name: origin,
        item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
        index: index,
        quantity: 1,
        item_category: pageLine?.liferayFields?.taxonomyCategoryBriefs?.[0]?.name,
        discount:
          specialPrice && regularPrice
            ? parseFloat((Number(regularPrice) - Number(specialPrice)).toFixed(2))
            : 0,
      };
    });

    //const currency = config.currency || "EUR";

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
        item_list_name: origin,
        items: list,
      },
    });
  } catch (err) {
    console.error("Error in productImpression: ", err);
  }
};

export const productFilters = (filterEvent) => {
  if (!window.dataLayer) {
    console.log("filterEvent", filterEvent);
    return;
  }

  try {
    window.dataLayer.push(filterEvent);
  } catch (err) {
    console.error("Error in productFilters: ", err);
  }
};

export const productClick = (
  babelProdotto,
  productRegularPrice,
  productSpecialPrice,
  origin = "Other",
  index
) => {
  if (!window.dataLayer) return;
  try {
    const { key_prod, productCode, pageLine, category } = babelProdotto;
    const regularPrice = productRegularPrice ? productRegularPrice : null;
    const specialPrice = productSpecialPrice
      ? productSpecialPrice
      : productRegularPrice
      ? productRegularPrice
      : null;
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_name: origin,
        item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
        items: [
          {
            item_name: productCode,
            item_id: key_prod,
            item_brand: "Rosieres",
            price: regularPrice,
            item_list_name: origin,
            item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
            index: index,
            quantity: 1,
            item_category: category ? category : pageLine?.taxonomyCategoryBriefs?.[0]?.name,
            discount:
              specialPrice && regularPrice
                ? parseFloat((Number(regularPrice) - Number(specialPrice)).toFixed(2))
                : 0,
          },
        ],
      },
    });
  } catch (err) {
    console.error("Error in productClick: ", err);
  }
};

export const eventImpression = (slug, eventCategory) => {
  if (!window.dataLayer) return;
  try {
    window.dataLayer.push({
      event: eventCategory,
      slug,
    });
  } catch (err) {
    console.error("Error in eventImpression: ", err);
  }
};

//TBD WITH ECOMMERCE
/* export const productAddCart = (babelProdotto, priceData) => {
  if (!window.dataLayer) return;
  try {
    const { key_prod, productCode, pageLine } = babelProdotto;
    const priceInfo = (priceData?.price_range ? priceData.price_range : babelProdotto.price_range)
      ?.minimum_price?.regular_price;
    const price = priceInfo;
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currencyCode: priceInfo?.currency || "EUR",
        add: {
          // 'add' actionFieldObject measures.
          products: [
            {
              //  adding a product to a shopping cart.
              name: productCode,
              id: key_prod,
              brand: "Haier",
              ...(price ? { price } : null),
              category: pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
              quantity: 1,
            },
          ],
        },
      },
    });
  } catch (err) {
    console.error("Error in productAddCart: ", err);
  }
}; */
